export const LookIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_36_6368)">
      <path
        d="M12.6668 5.616V4.66667C12.6668 3.42899 12.1752 2.242 11.3 1.36684C10.4248 0.491665 9.23784 0 8.00016 0C6.76249 0 5.5755 0.491665 4.70033 1.36684C3.82516 2.242 3.3335 3.42899 3.3335 4.66667V5.616C2.73974 5.87514 2.23436 6.30168 1.87916 6.84347C1.52397 7.38527 1.33435 8.01882 1.3335 8.66667V12.6667C1.33455 13.5504 1.68608 14.3976 2.31098 15.0225C2.93587 15.6474 3.7831 15.9989 4.66683 16H11.3335C12.2172 15.9989 13.0645 15.6474 13.6893 15.0225C14.3142 14.3976 14.6658 13.5504 14.6668 12.6667V8.66667C14.666 8.01882 14.4764 7.38527 14.1212 6.84347C13.766 6.30168 13.2606 5.87514 12.6668 5.616ZM4.66683 4.66667C4.66683 3.78261 5.01802 2.93477 5.64314 2.30964C6.26826 1.68452 7.11611 1.33333 8.00016 1.33333C8.88422 1.33333 9.73206 1.68452 10.3572 2.30964C10.9823 2.93477 11.3335 3.78261 11.3335 4.66667V5.33333H4.66683V4.66667ZM13.3335 12.6667C13.3335 13.1971 13.1228 13.7058 12.7477 14.0809C12.3726 14.456 11.8639 14.6667 11.3335 14.6667H4.66683C4.1364 14.6667 3.62769 14.456 3.25262 14.0809C2.87754 13.7058 2.66683 13.1971 2.66683 12.6667V8.66667C2.66683 8.13623 2.87754 7.62753 3.25262 7.25245C3.62769 6.87738 4.1364 6.66667 4.66683 6.66667H11.3335C11.8639 6.66667 12.3726 6.87738 12.7477 7.25245C13.1228 7.62753 13.3335 8.13623 13.3335 8.66667V12.6667Z"
        fill="#8B90B3"
      />
      <path
        d="M8.00016 9.33331C7.82335 9.33331 7.65378 9.40355 7.52876 9.52858C7.40373 9.6536 7.3335 9.82317 7.3335 9.99998V11.3333C7.3335 11.5101 7.40373 11.6797 7.52876 11.8047C7.65378 11.9297 7.82335 12 8.00016 12C8.17697 12 8.34654 11.9297 8.47157 11.8047C8.59659 11.6797 8.66683 11.5101 8.66683 11.3333V9.99998C8.66683 9.82317 8.59659 9.6536 8.47157 9.52858C8.34654 9.40355 8.17697 9.33331 8.00016 9.33331Z"
        fill="#8B90B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_36_6368">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
