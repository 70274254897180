export const EmailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 0.666687H3.33333C2.4496 0.667746 1.60237 1.01928 0.97748 1.64417C0.352588 2.26906 0.00105857 3.11629 0 4.00002L0 12C0.00105857 12.8838 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3323 3.33333 15.3334H12.6667C13.5504 15.3323 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8838 16 12V4.00002C15.9989 3.11629 15.6474 2.26906 15.0225 1.64417C14.3976 1.01928 13.5504 0.667746 12.6667 0.666687ZM3.33333 2.00002H12.6667C13.0659 2.00081 13.4557 2.12103 13.786 2.34522C14.1163 2.56942 14.3719 2.88732 14.52 3.25802L9.41467 8.36402C9.03895 8.73823 8.53028 8.94833 8 8.94833C7.46972 8.94833 6.96105 8.73823 6.58533 8.36402L1.48 3.25802C1.6281 2.88732 1.88374 2.56942 2.21403 2.34522C2.54432 2.12103 2.93414 2.00081 3.33333 2.00002ZM12.6667 14H3.33333C2.8029 14 2.29419 13.7893 1.91912 13.4142C1.54405 13.0392 1.33333 12.5305 1.33333 12V5.00002L5.64267 9.30669C6.26842 9.93086 7.11617 10.2814 8 10.2814C8.88383 10.2814 9.73158 9.93086 10.3573 9.30669L14.6667 5.00002V12C14.6667 12.5305 14.456 13.0392 14.0809 13.4142C13.7058 13.7893 13.1971 14 12.6667 14Z"
      fill="#8B90B3"
    />
  </svg>
);
