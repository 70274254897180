import React, { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import ButtonSecondary from "../../buttons/ButtonSecondary/ButtonSecondary";
import { MicrosoftIcon } from "../../icons/microsoft";

export const SignUpWithMicrosoft = () => {
  const [loading, setLoading] = useState(false);
  const { loginWithMicrosoft } = useAuth();

  const handleMicrosoftLogin = async () => {
    setLoading(true);
    loginWithMicrosoft()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ButtonSecondary
      variant="white"
      className="base-1 white"
      loading={loading}
      text={"Continue with Microsoft"}
      onClick={handleMicrosoftLogin}
      icon={<MicrosoftIcon />}
    />
  );
};
