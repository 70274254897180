import React, { CSSProperties } from "react";
import Loader from "../../Loaders/LoaderCircle/Loader";
import styles from "./ButtonPrimary.module.scss";

interface ButtonPrimaryProps {
  variant?: "default" | "large";
  loading: boolean;
  disabled?: boolean;
  text: string;
  onClick: (event?: any) => any;
  styles?: CSSProperties;
  className?: any;
  loaderColor?: string;
}

const ButtonPrimary = (props: ButtonPrimaryProps) => {
  return (
    <button
      style={props.styles}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      className={`${styles[props.variant || "default"]} ${
        props.disabled && styles.disabled
      } ${props.className}`}
    >
      {props.loading ? (
        <Loader
          isLoading={props.loading}
          color={props.loaderColor || "accent-foreground"}
          width={24}
          height={24}
        />
      ) : (
        <span className="accent-foreground">{props.text}</span>
      )}
    </button>
  );
};

export default ButtonPrimary;
